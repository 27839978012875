import c0 from './aluno_visibilidade_documentos_controller';
import c1 from './centro_laboratorios_controller';
import c2 from './disable_submit_controller';
import c3 from './equivalencias_controller';
import c4 from './exclusive_checkbox_controller';
import c5 from './fotos_controller';
import c6 from './linha_de_pesquisa_controller';
import c7 from './matriz/escopo_controller';
import c8 from './matriz/grupo_controller';
import c9 from './matriz/periodo_controller';
import c10 from './matriz/proficiencias_controller';
import c11 from './minha_biblioteca_controller';
import c12 from './plano_controller';
import c13 from './redirect_controller';
import c14 from './requerimento_transferencia_de_turma_controller';
import c15 from './tracker_controller';
import c16 from './vacinacao_controller';
export const definitions = [
	{identifier: 'aluno-visibilidade-documentos', controllerConstructor: c0},
	{identifier: 'centro-laboratorios', controllerConstructor: c1},
	{identifier: 'disable-submit', controllerConstructor: c2},
	{identifier: 'equivalencias', controllerConstructor: c3},
	{identifier: 'exclusive-checkbox', controllerConstructor: c4},
	{identifier: 'fotos', controllerConstructor: c5},
	{identifier: 'linha-de-pesquisa', controllerConstructor: c6},
	{identifier: 'matriz--escopo', controllerConstructor: c7},
	{identifier: 'matriz--grupo', controllerConstructor: c8},
	{identifier: 'matriz--periodo', controllerConstructor: c9},
	{identifier: 'matriz--proficiencias', controllerConstructor: c10},
	{identifier: 'minha-biblioteca', controllerConstructor: c11},
	{identifier: 'plano', controllerConstructor: c12},
	{identifier: 'redirect', controllerConstructor: c13},
	{identifier: 'requerimento-transferencia-de-turma', controllerConstructor: c14},
	{identifier: 'tracker', controllerConstructor: c15},
	{identifier: 'vacinacao', controllerConstructor: c16},
];
